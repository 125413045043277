import React from "react";
import * as styles from './partners.module.scss';
import useMediaQuery from "../../../utils/useMediaQuery";

const Partners = (props) => {
    const { data, themeContext } = props;
    const large = useMediaQuery('(max-width: 1100px)')
    const small = useMediaQuery('(max-width: 500px)')
    return (
        <>
            <div className={(themeContext === 'stansonHealth' ? styles.stansonPartners :
                             themeContext === 'appliedSciences' ? styles.appliedPartners :
                             styles.partnersContainer)}>
                <div className={styles.titleContainer}>
                    <h3>{data.title}</h3>
                </div>
                <div className={styles.logoContainer} style={small ? 
                {gridTemplateColumns: 'repeat(1, minmax(200px, 1fr))'}
                : large ?
                {gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))'}
                :
                {gridTemplateColumns: 'repeat(' + data.columns + ', minmax(275px, 1fr))'}
            }>
                    {data.partnerLogos.map((logo, index) => (
                        <div className={styles.imageContainer} key={index}>
                            <img src={logo.asset?.url} alt={index} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Partners