import { useState, useEffect } from 'react';

// Custom hook for detecting Safari on desktop
const useSafariDesktopDetection = () => {
  const [isSafariDesktop, setIsSafariDesktop] = useState(null);

  useEffect(() => {
    // Detect Safari on desktop by checking for "Safari" in the user agent string
    // and excluding "Chrome," "Chromium," and "Mobile" to avoid mobile Safari.
    const isSafariDesktop = /^((?!Chrome|Chromium|Mobile).)*Safari/i.test(navigator.userAgent);

    // Set state
    setIsSafariDesktop(isSafariDesktop);
  }, []);

  return isSafariDesktop;
};

export default useSafariDesktopDetection;
