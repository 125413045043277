import React, { useState } from 'react'
import ArrowIcon from '../../svg/ArrowIcon'
import { Link } from 'gatsby'
import pincailogo from '../../../assets/images/pincai-text-logo.svg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import HeroAnimation from './HeroAnimation/HeroAnimation'
import * as styles from './Hero.module.scss'
// import LiveChat from '../../LiveChat/LiveChat'
import ScrollObserver from '../../ScrollObserver/ScrollObserver'
import { PortableText } from '@portabletext/react'

// import circlearrow from '../../../assets/images/circle-arrow.svg'
// import PlayIcon from "../../svg/PlayIcon";
// import rightimg from '../../../assets/images/hero-right-img.png'
// import BackgroundVideo from '../../BackgroundVideo/BackgroundVideo'
// import VideoPopup from "../../VideoPopup/VideoPopup";
// import herobg from '../../../assets/images/bg-hero.png'
// import HeroOverlay from './HeroOverlay'

const LeftButton = ({ text, link, className }) => {
  const isExternalLink = (text) => {
    return /http/i.test(text)
  }

  return (
    <>
      {isExternalLink(link) ? (
        <a
          href={link}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ) : (
        <Link to={link} className={className}>
          {text}
        </Link>
      )}
    </>
  )
}

export default function Hero(props) {
  const { data } = props

  const { heroLeftBlock, heroRightBlock } = data

  const [isVisible, setIsVisible] = useState(true)

  const rightImage = getImage(heroRightBlock.headerImg.asset)

  //   const containerRef = useRef();
  //   const [videoIsOpen, setVideoIsOpen] = useState(false);

  // for testing the hero animation with nothing else over it:
  //   return (
  //     <div className={styles.sectionHero} ref={containerRef}>
  //       <div className={styles.sectionHeroBg}>
  //         <HeroAnimation />
  //       </div>
  //     </div>
  //   );

  return (
    <>
      <ScrollObserver setIsVisible={setIsVisible}>
        <div className={styles.sectionHero}>
          <div className={styles.sectionHeroBg}>
            {/* <HeroOverlay /> */}
            {isVisible && <HeroAnimation />}
          </div>
          <div className={styles.sectionHeroContent}>
            <div className={styles.sectionHeroContentLeft}>
              <div className={styles.sectionHeroContentLeftTitle}>
                <img src={pincailogo} alt="PINC AI Logo" />
              </div>
              <h1 className={styles.sectionHeroContentLeftContent}>
                {heroLeftBlock.bodyText}
              </h1>
              <LeftButton
                text={heroLeftBlock.buttonText}
                link={heroLeftBlock.buttonLink}
                className={styles.sectionHeroContentLeftButton}
              />
            </div>
            <div className={styles.sectionHeroContentRight}>
              <div className={styles.sectionHeroContentRightImg}>
                {/* <img src={rightimg} alt="PINC AI" /> */}
                <GatsbyImage
                  image={rightImage}
                  alt="PINC AI"
                  layout="fullWidth"
                />
              </div>
              <div className={styles.sectionHeroContentRightBody}>
                <div className={styles.sectionHeroContentRightTitle}>
                  <PortableText value={heroRightBlock.bodyTitle._rawTextEditor}/>
                </div>
                <div className={styles.sectionHeroContentRightContent}>
                  <PortableText value={heroRightBlock.bodyText._rawTextEditor}/>
                </div>
                <Link
                  to={heroRightBlock.buttonLink}
                  className={styles.sectionHeroContentRightButton}
                >
                  <span>{heroRightBlock.buttonText}</span> <ArrowIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <LiveChat /> */}
      </ScrollObserver>
    </>
  )
}
