import React, { Suspense } from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../components/Layout/Layout'
import Hero from '../components/HomePage/Hero/Hero'
import Foh from '../components/HomePage/Foh/Foh'
// import CaseStudies from '../components/HomePage/CaseStudies/CaseStudies'
import Products from '../components/HomePage/Products/Products'
import Solutions from '../components/HomePage/Solutions/Solutions'
import Connect from '../components/HomePage/Connect/Connect'
import Seo from '../components/Seo/Seo'

import { graphql } from 'gatsby'
import Partners from '../components/HomePage/Partners/partners'
// import About from '../components/HomePage/About/about'
import AboutLink from '../components/HomePage/AboutLink/aboutLink'

export const query = graphql`
  query home($homePageVersion: String!) {
    home: sanityHomepage(id: { eq: $homePageVersion }) {
      id
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      blog {
        link
        text
      }
      caseStudies {
        title
        bodyText
        backgroundImage {
          asset {
            gatsbyImageData
          }
        }
        link
      }
      connect: connectSection {
        title
        bodyText
        backgroundImage {
          asset {
            gatsbyImageData
          }
        }
        link
      }
      foh: fohSection {
        title
        bodyText
        headerText
      }
      footer: footerSection {
        footerContact {
          addressCityStateZip
          addressStreet
          phone1
          phone2
          socials {
            title
            link
            icon {
              asset {
                gatsbyImageData
              }
            }
          }
        }
        footerCopyright {
          copyrightText
          icon {
            asset {
              gatsbyImageData
            }
          }
        }
        footerLinkColumns {
          title
          links {
            text
            link
          }
        }
      }
      hero: heroSection {
        heroLeftBlock {
          bodyText
          buttonText
          buttonLink
          headerImg {
            asset {
              gatsbyImageData
            }
          }
        }
        heroRightBlock {
          bodyTitle {
            _rawTextEditor
          }
          bodyText {
            _rawTextEditor
          }
          buttonText
          buttonLink
          headerImg {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      product: productSection {
        title
        bodyText
        backgroundImage {
          asset {
            gatsbyImageData
          }
        }
      }
      solution: solutionSection {
        clinicalIntelligenceSection {
          title
          bodyText
          link
          backgroundImage {
            asset {
              url
            }
          }
        }
        valueBasedCareSection {
          title
          bodyText
          backgroundImage {
            asset {
              url
            }
          }
          link
        }
        marginImprovementSection {
          title
          bodyText
          backgroundImage {
            asset {
              url
            }
          }
          link
        }
      }
      about: aboutSection {
        title
        _rawDescription
        image {
          asset {
            url
          }
        }
        button {
          buttonText
          buttonData
          buttonFile {
            asset {
              url
            }
          }
        }
      }
      aboutLink: homeAboutLinkSection {
        links {
          title
          _rawDescription
          image {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          button {
            buttonText
            buttonContext
            buttonData
            buttonFile {
              asset {
                url
              }
            }
          }
        }
      }
      partner: partnerSection {
        title
        columns
        partnerLogos {
          asset {
            url
          }
        }
      }
    }
  }
`

const HomeTemplate = (props) => {
  const { data } = props

  const { 
    // caseStudies,
    connect, foh, hero, product, solution, 
    // about,
     aboutLink, partner } = data.home

  return (
    <Layout>

      <Hero data={hero} />

      {/* <Blog data={blog}/> */}

      <Foh data={foh} />

      <Suspense fallback={<div>Loading...</div>}>
        <Products data={product} />
      </Suspense>

      <Solutions data={solution} />

      {/* <CaseStudies data={caseStudies} /> */}

      <Partners data={partner} />

      {/* <About data={about} /> */}

      <AboutLink data={aboutLink} />

      <Connect data={connect} />
    </Layout>
  )
}

export default HomeTemplate

export const Head = (props) => {
  const { home } = props.data;
  return (
    <Seo title={home.browserTitle} seo={home.seoData} />
  )
}
