export const ParticleShader = {
  
  vertexShader: [
    "attribute float size;",
    "attribute float alpha;",
    
    "varying float vAlpha;",

		"void main() {",
      
      "vAlpha = alpha;",
			"vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
			"gl_PointSize = size * 10.0;",
			"gl_Position = projectionMatrix * mvPosition;",
      
		"}",
  ].join("\n"),
  
  fragmentShader: [
    "uniform vec3 color;",
		"uniform sampler2D pointTexture;",
    
    "varying float vAlpha;",

		"void main() {",

			"gl_FragColor = vec4( color, vAlpha );",
			"gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );",

		"}",
  ].join("\n"),
}