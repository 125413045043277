// extracted by mini-css-extract-plugin
export var aqua = "SolutionItem-module--aqua--96e73";
export var arrow = "SolutionItem-module--arrow--df0ab";
export var blue = "SolutionItem-module--blue--ce752";
export var hover = "SolutionItem-module--hover--338e1";
export var linkText = "SolutionItem-module--linkText--8a992";
export var linkWrapper = "SolutionItem-module--linkWrapper--8aeec";
export var mango = "SolutionItem-module--mango--cb6b8";
export var parentHover = "SolutionItem-module--parentHover--9df64";
export var subtitle = "SolutionItem-module--subtitle--51c98";
export var violet = "SolutionItem-module--violet--76619";