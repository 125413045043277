// private static vars
const sceneObjects = [];


// class
export class SceneObjects {
  
  // public static methods
  static add( object ) {
    sceneObjects.push( object );
  }
  
  static update() {
    sceneObjects.forEach( object => object.update() );
  }

  static disposeAll() {
    sceneObjects.forEach( object => { if ( object.dispose ) object.dispose(); });
    sceneObjects.length = 0;
  }

  static doNextInteraction() {
    sceneObjects.forEach( object => {
      if ( object.doNextInteraction !== undefined ) object.doNextInteraction();
    });
  }
  
  static onWindowResize() {
    sceneObjects.forEach( object => {
      if ( object.onWindowResize !== undefined ) object.onWindowResize();
    });
  }
  
  static find( className ) {
    for ( var i = 0; i < sceneObjects.length; i++ ) {
      if ( sceneObjects[i].constructor.name === className ) return sceneObjects[ i ];
    }
  }
}