import React from 'react';
import Modal from 'react-modal'
import PincHelmet from '../../components/Helmet/PincHelmet'
import * as styles from './VideoPopup.module.scss'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    paddingTop: '5px',
    marginRight: '-50%',
    paddingBottom: '5px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0, 0.8)',
  },
};

const VideoPopup = (props) => {

  const { isOpen, setIsOpen, title, link } = props;

  Modal.setAppElement('#___gatsby')

  return (
    <Modal
      closeTimeoutMS={200} // needs to match css transition duration
      isOpen={isOpen}
      onRequestClose={() => { setIsOpen(false); if (window.HeroAnimationAPI?.unpause) window.HeroAnimationAPI.unpause(); }}
      style={modalStyles}
    >
      <PincHelmet>
        <link rel="stylesheet" href="https://player.manage.broadcastcloud.tv/css/embed-player.css" />
        <script type="text/javascript" src="https://player.manage.broadcastcloud.tv/js/embed-player.js"></script>
      </PincHelmet>
      <div className={styles.container}>
        {/* <h3 className={styles.title}>{title}</h3> */}
        {/* vimeo video */}
        <div>
          <div className={styles.keepaspect169}>
            <iframe
                title={title}
                src={link}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                seamless="seamless"
                allowFullScreen
              ></iframe>
          </div>
        </div>
        {/* close icon */}
        <button
          type="button"
          className={`${styles.modalclosebutton}`}
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => { setIsOpen(false); if (window.HeroAnimationAPI?.unpause) window.HeroAnimationAPI.unpause(); }}
        >
          <svg fill="none" height="44" viewBox="0 0 44 44" width="44" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#fff" strokeWidth="2">
              <circle cx="22" cy="22" fill="#000" r="21" />
              <path d="m15.7174 15.7175 13.435 13.435" />
              <path d="m15.7177 29.1525 13.435-13.435" />
            </g>
          </svg>
        </button>
      </div>
    </Modal>
  );
};

export default VideoPopup;
