
// support functions

export function getRandomNumberInRange( min, max ) {
  return Math.random() * ( max - min ) + min;
}

export function gaussianRand() {
  var rand = 0;
  for ( var i = 0; i < 6; i += 1 ) {
    rand += Math.random();
  }
  return rand / 6;
}

export function getRandomNumberInRangeWithNormalDistribution( min, max ) {
  return min + gaussianRand() * ( max - min );
}

export function getRandomNumberInRanges( minA, maxA, minB, maxB, probabilityOfA ) {
  if ( getRandomNumberInRange( 0, 1 ) <= probabilityOfA ) {
    return getRandomNumberInRange( minA, maxA );
  } else {
    return getRandomNumberInRange( minB, maxB );
  }
}


// eslint-disable-next-line no-extend-native
Number.prototype.map = function ( in_min, in_max, out_min, out_max ) {
  return ( this - in_min ) * ( out_max - out_min ) / ( in_max - in_min ) + out_min;
}

// eslint-disable-next-line no-extend-native
Number.prototype.clampToRange = function ( min, max ) {
  return Math.min( Math.max( this, min ), max );
}

// eslint-disable-next-line no-extend-native
Array.prototype.selectRandom = function () {
  return this[ Math.floor( Math.random() * this.length ) ];
}

// eslint-disable-next-line no-extend-native
Array.prototype.shuffle = function () {
  for ( let i = this.length - 1; i > 0; i-- ) {
    const j = Math.floor( Math.random() * i );
    const temp = this[ i ];
    this[ i ] = this[ j ];
    this[ j ] = temp;
  }
}

export function getPointerX( event ) {
  return (typeof event.pageX !== "undefined") ? event.pageX : event.touches[0].pageX;
}

export function getPointerY( event ) {
  return (typeof event.pageY !== "undefined") ? event.pageY : event.touches[0].pageY;
}

export function getPointerXY( event ) {
  return [ getPointerX( event ), getPointerY( event ) ];
}

export function getPointerClientX( event ) {
  return (typeof event.clientX !== "undefined") ? event.clientX : event.touches[0].clientX;
}

export function getPointerClientY( event ) {
  return (typeof event.clientY !== "undefined") ? event.clientY : event.touches[0].clientY;
}

export function getPointerClientXY( event ) {
  return [ getPointerClientX( event ), getPointerClientY( event ) ];
}

export function getPointerOffsetX( event ) {
  return (typeof event.offsetX !== "undefined") ? event.offsetX : event.touches[0].offsetX;
}

export function getPointerOffsetY( event ) {
  return (typeof event.offsetY !== "undefined") ? event.offsetY : event.touches[0].offsetY;
}

export function getPointerOffsetXY( event ) {
  return [ getPointerOffsetX( event ), getPointerOffsetY( event ) ];
}