import React, { useState } from 'react'
import SolutionItem from './SolutionItem'
import { StaticImage } from 'gatsby-plugin-image'
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group'
import bgImage1 from '../../../assets/images/solutions/solution-bg-image-1.jpg'
import bgImage2 from '../../../assets/images/solutions/solution-bg-image-2.jpg'
import bgImage3 from '../../../assets/images/solutions/solution-bg-image-3.jpg'
import bgImage4 from '../../../assets/images/solutions/solution-bg-image-4.jpg'
import bgImage5 from '../../../assets/images/solutions/solution-bg-image-5.jpg'
import * as styles from './Solutions.module.scss'

const Animate = ({ children, ...rest }) => {
  // console.log('props', rest)
  return (
    <CSSTransition
      {...rest}
      timeout={700}
      classNames="bg-image"
      // mountOnEnter
      // unmountOnExit
    >
      {children}
    </CSSTransition>
  )
}

export default function Solutions(props) {
  const { data } = props

  // background image id for content box, -1 for no background image
  const [hoverBgImage, setHoverBgImage] = useState(-1)

  const solutions = [
    {
      id: 1,
      linkText: data.clinicalIntelligenceSection.title,
      link: data.clinicalIntelligenceSection.link,
      subtitle: 'Smarter Care. Better results.',
      color: 'aqua',
    },
    {
      id: 2,
      linkText: data.valueBasedCareSection.title,
      link: data.valueBasedCareSection.link,
      subtitle: 'Stay Ahead of the Curve',
      color: 'mango',
    },
    {
      id: 3,
      linkText: data.marginImprovementSection.title,
      link: data.marginImprovementSection.link,
      subtitle: 'Partnering for Fiscal Sustainability',
      color: 'violet',
    },
    {
      id: 4,
      linkText: 'APPLIED SCIENCES',
      link: '/applied-sciences',
      subtitle: 'Accelerating Value Through Evidence',
      color: 'blue',
    },
    {
      id: 5,
      linkText: 'STANSON HEALTH',
      // link: '/stanson-health',
      link: 'https://stansonhealth.com',
      subtitle: 'The Future of Healthcare',
    },
  ]

  // build array of solution items

  const solutionList = solutions.map((solution, index) => {
    return (
      <SolutionItem
        solution={solution}
        key={index}
        hoverBgImage={hoverBgImage}
        setHoverBgImage={setHoverBgImage}
      />
    )
  })

  // classnames for content box

  const contentClassnames = `${styles.content} ${
    hoverBgImage !== -1 ? styles.contentHover : ''
  }`

  return (
    <section className={styles.container} id="solutions">
      <div className={styles.background}>
        <StaticImage
          src="../../../assets/images/Solutions-Background-Optimized.jpg"
          alt="Abstract background"
          className={styles.backgroundImage}
        />
      </div>
      <div className={contentClassnames}>
        <TransitionGroup enter exit>
          <Animate key={hoverBgImage}>
            <>
              {/* outer divs needed for CssTransition to apply enter and exit classes */}
              {hoverBgImage === 1 && (
                <div>
                  <img
                    className={styles.bgImage}
                    src={bgImage1}
                    alt="Background"
                  />
                </div>
              )}
              {hoverBgImage === 2 && (
                <div>
                  <img
                    className={styles.bgImage}
                    src={bgImage2}
                    alt="Background"
                  />
                </div>
              )}
              {hoverBgImage === 3 && (
                <div>
                  <img
                    className={styles.bgImage}
                    src={bgImage3}
                    alt="Background"
                  />
                </div>
              )}
              {hoverBgImage === 4 && (
                <div>
                  <img
                    className={styles.bgImage}
                    src={bgImage4}
                    alt="Background"
                  />
                </div>
              )}
              {hoverBgImage === 5 && (
                <div>
                  <img
                    className={styles.bgImage}
                    src={bgImage5}
                    alt="Background"
                  />
                </div>
              )}
            </>
          </Animate>
        </TransitionGroup>
        <h6 className={styles.title}>OUR PORTFOLIOS</h6>
        {solutionList}
      </div>
    </section>
  )
}
