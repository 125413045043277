import React, { useState } from 'react'
import Link from '../../../Link/Link'
import MarketoPopup from '../../../MarketoPopup/MarketoPopup'
import VideoPopup from '../../../VideoPopup/VideoPopup'
import useSafariDesktopDetection from '../../../../utils/useSafariDesktopDetection'
import EbookArrow from '../../../svg/EbookArrow'
import DownloadArrow from '../../../svg/DownloadArrow'
// import * as styles from './buttonTemplate.module.scss'

const ButtonTemplate = (props) => {
  const { data } = props
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [videoIsOpen, setVideoIsOpen] = useState(false)
  const isSafariDesktop = useSafariDesktopDetection()

  // 'file'
  // 'link'
  // 'modalForm'
  // 'video'

  // get filename from sanity url - for use with Safari desktop only
  let filename = 'default.pdf '
  if (data?.buttonContext === 'file' && isSafariDesktop) {
    filename = data?.buttonFile?.asset?.url.split('/').pop()
    console.log('filename', filename)
  }

  return (
    <>
      {data?.buttonContext === 'file' && isSafariDesktop && (
        <a href={`${data?.buttonFile?.asset?.url}?dl=${filename}`} target="_blank" rel="noopener noreferrer">
          {props.children} {data?.showArrow && <DownloadArrow />}
        </a>
      )}
      {data?.buttonContext === 'file' && !isSafariDesktop && (
        <a href={data?.buttonFile?.asset?.url + '?dl='} target="_blank" rel="noopener noreferrer">
          {props.children} {data?.showArrow && <DownloadArrow />}
        </a>
      )}
      {data?.buttonContext === 'link' && (
        <Link to={data?.buttonData || '/'}>
          {props.children} {data?.showArrow && <EbookArrow />}
        </Link>
      )}
      {data?.buttonContext === 'modalForm' && (
        <>
          <button onClick={() => setFormIsOpen(true)}>
            {props.children} {data?.showArrow && <EbookArrow />}
          </button>
          <MarketoPopup isOpen={formIsOpen} setIsOpen={setFormIsOpen} marketoFormID={data?.buttonData} />
        </>
      )}
      {data?.buttonContext === 'video' && (
        <>
          <button onClick={() => setVideoIsOpen(true)}>
            {props.children} {data?.showArrow && <EbookArrow />}
          </button>
          <VideoPopup
            title="WHAT IS PINC AI?"
            isOpen={videoIsOpen}
            setIsOpen={setVideoIsOpen}
            link={data?.buttonData}
          />
        </>
      )}
    </>
  )
}

export default ButtonTemplate
