import React, { useState, useRef, lazy } from 'react'
import pincai from '../../../assets/images/pincai-icon.svg'
import PlayIcon from '../../svg/PlayIcon'
import VideoPopup from '../../VideoPopup/VideoPopup'
import * as styles from './Products.module.scss'

const BackgroundVideo = lazy(() => import('../../BackgroundVideo/BackgroundVideo') ) 

export default function Products(props) {
  const { data } = props

  const { title, bodyText } = data

  // video popup
  const containerRef = useRef()
  const [videoIsOpen, setVideoIsOpen] = useState(false)

  return (
    <>
      <VideoPopup
        appContainerRef={containerRef.current}
        title="WHAT IS PINC AI?"
        isOpen={videoIsOpen}
        setIsOpen={setVideoIsOpen}
        link="https://player.vimeo.com/video/579514756"
      />
      <div className={styles.container} ref={containerRef}>
        <div className={styles.videoBackground}>
          <BackgroundVideo url="https://stream.mux.com/8p2Flh46EfOvQSIIq6lmpnY278LFw013ActJX01rUT8TI.m3u8" />
        </div>
        <img src={pincai} alt="Pinc AI Logo" className={styles.logo} />
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.content}>{bodyText}</div>
        <button
          className={styles.videoButton}
          onClick={() => setVideoIsOpen(true)}
          type="button"
        >
          WHAT IS PINC AI? <PlayIcon />
        </button>
      </div>
    </>
  )
}
