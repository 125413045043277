import React from 'react'
import Modal from 'react-modal'
import MarketoContactForm from '../ContactForm/MarketoContactForm'
import * as styles from './MarketoPopup.module.scss'

const modalStyles = {
  content: {
    top: '140px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    paddingTop: '5px',
    marginRight: '-50%',
    paddingBottom: '140px',
    transform: 'translatex(-50%)',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0, 0.8)',
  },
}

const MarketoPopup = (props) => {
  const { isOpen, setIsOpen
    // category = null
  } = props
  let marketoFormID = props.marketoFormID || 5050

  // important for users of screenreaders that other page content be hidden
  Modal.setAppElement('#___gatsby');

  // set marketo form id based on category
  // ToDo: add form codes for each solution
  let formID = 'mktoForm_' + marketoFormID;
  let formCode = marketoFormID
  // switch (category) {
  //   case 'Clinical Intelligence':
  //     formID = 'mktoForm_5050'
  //     formCode = 5050
  //     break
  //   case 'Value-Based Care':
  //     formID = 'mktoForm_5050'
  //     formCode = 5050
  //     break
  //   case 'Margin Improvement':
  //     formID = 'mktoForm_5050'
  //     formCode = 5050
  //     break
  //   default:
  //     formID = 'mktoForm_5050'
  //     formCode = 5050
  //     break
  // }
  // console.log(formID, formCode)

  return (
    <Modal
      closeTimeoutMS={200} // needs to match css transition duration
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyles}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
    >
      {/* <Script src="https://offers.premierinc.com/js/forms2/js/forms2.min.js"/> */}
      <div className={styles.container}>
        {/* <MarketoContactForm formID="mktoForm_4250" formCode={4250} /> */}
        <MarketoContactForm 
          formID={formID} 
          formCode={formCode} 
          heading='Let’s Talk.'
          subheading='Fill out the form below to connect with our team. We look forward to hearing from you.'
          />
        {/* close icon */}
        <button
          type="button"
          className={`${styles.modalclosebutton}`}
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => props.setIsOpen(false)}
        >
          <svg
            fill="none"
            height="44"
            viewBox="0 0 44 44"
            width="44"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="#616161" strokeWidth="2">
              <circle cx="22" cy="22" fill="#000" r="21" />
              <path d="m15.7174 15.7175 13.435 13.435" />
              <path d="m15.7177 29.1525 13.435-13.435" />
            </g>
          </svg>
        </button>
      </div>
    </Modal>
  )
}

export default MarketoPopup
