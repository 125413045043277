import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from './Foh.module.scss'

export default function Foh(props) {
  const { data } = props

  const { title, bodyText, headerText } = data

  return (
    <div className={styles.sectionFoh}>
      <h2 className={styles.sectionFohTitle}>{title}</h2>
      <div className={styles.sectionFohContent}>
        <StaticImage
          src="../../../assets/images/foh-bg.jpg"
          quality={100}
          alt="Horizon"
          className={styles.sectionFohContentImg}
          layout="fullWidth"
        />
        <h2 className={styles.sectionFohContentHeader}>{headerText}</h2>
        <p className={styles.sectionFohContentText}>{bodyText}</p>
        {/* <hr /> */}
        {/* <p className={styles.instructions}>
          <strong>Download the PINC AI™ capabilities brochure</strong>
          <br />
          to learn about our data and technology solutions.
        </p>
        <a
          download
          href="https://storage.googleapis.com/pinc-ai-phase2.appspot.com/PDFs/PINC%20AI%20Brochure.pdf"
          className={styles.sectionFohContentButton}
          target="_blank"
          rel="noopener noreferrer"
        >
          DOWNLOAD NOW <DownloadIcon />
        </a> */}
      </div>
    </div>
  )
}
