import React from "react";

function PlayIcon(props) {
  const { color = "#da437e"} = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle cx="10" cy="10" r="9.5" stroke={color}></circle>
      <path fill={color} d="M14 10l-6 3.464V6.536z"></path>
    </svg>
  );
}

export default PlayIcon;