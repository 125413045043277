import React from "react";
import LinkBlock from "../../AppliedSciences/ASComponents/linkBlock/linkBlock";
import * as styles from './aboutLink.module.scss';

const AboutLink = (props) => {
    const { data } = props;
    
    // console.log(data);

    return (
        <>
        <div className={styles.aboutLinksContainer}>
            <div className={styles.linksContainer}>
                {data.links.map((link, index) => (
                    <LinkBlock data={link} context={'home'} key={index} themeContext={'homePage'} />
                ))
                }
            </div>
        </div>
        </>
    )
}

export default AboutLink