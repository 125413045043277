import * as THREE from "three";
import { SceneUtils } from './SceneUtils';
import * as Support from "../utils/hero-animation-support";
import { FrameRate } from "./FrameRate";

// config
const cameraStartingPosition = new THREE.Vector3(0, 0, 0);

let cameraMoveDistanceX = 3; // will be scaled down on touch devices
let cameraMoveDistanceY = 1.2; // will be scaled down on touch devices
let cameraRotationAmountHorizontal = 0.3; // will be scaled down on touch devices
let cameraRotationAmountVertical = 0.15; // will be scaled down on touch devices
const cameraMoveSpeed = 0.01;
const fov = 50;
const nearClip = 0.1;
const farClip = 100;


// private vars
let camera;


// private methods
function onPointerMove(event) {
  var [x, y] = Support.getPointerXY(event);
  camera.userData.targetPosition.x = x.map(SceneUtils.screenWidth(), 0, cameraMoveDistanceX, -cameraMoveDistanceX);
  camera.userData.targetPosition.y = y.map(SceneUtils.screenHeight(), 0, -cameraStartingPosition.y - cameraMoveDistanceY, -cameraStartingPosition.y + cameraMoveDistanceY);
  camera.userData.targetRotation.x = y.map(SceneUtils.screenHeight(), 0, cameraRotationAmountVertical, -cameraRotationAmountVertical);
  camera.userData.targetRotation.y = x.map(SceneUtils.screenWidth(), 0, cameraRotationAmountHorizontal, -cameraRotationAmountHorizontal);
}




// class
export class Camera {
  
  constructor() {
    camera = new THREE.PerspectiveCamera(fov, SceneUtils.screenWidth() / SceneUtils.screenHeight(), nearClip, farClip);
    
    camera.position.copy(cameraStartingPosition);

    camera.userData.targetPosition = camera.position.clone();
    camera.userData.targetRotation = camera.rotation.clone();
    
    // scale down config on touch devices
    if ( SceneUtils.isTouchDevice() ) {
      cameraMoveDistanceX = 0;
      cameraMoveDistanceY *= 0.1;
      cameraRotationAmountHorizontal = 0;
      cameraRotationAmountVertical *= 0.1;
    }

    window.addEventListener('pointermove', onPointerMove);
  }
  
  get camera() {
    return camera;
  }
  
  update() {

    // move camera toward its target
    let speedFactor = FrameRate.speedFactor;

    let cameraDeltaX = (camera.userData.targetPosition.x - camera.position.x) * cameraMoveSpeed * speedFactor;
    let cameraDeltaY = (camera.userData.targetPosition.y - camera.position.y) * cameraMoveSpeed * speedFactor;
    let cameraRotationDeltaX = (camera.userData.targetRotation.x - camera.rotation.x) * cameraMoveSpeed * speedFactor;
    let cameraRotationDeltaY = (camera.userData.targetRotation.y - camera.rotation.y) * cameraMoveSpeed * speedFactor;

    if (Math.abs(cameraDeltaX) > 0.001 || Math.abs(cameraDeltaY) > 0.001 || Math.abs(cameraRotationDeltaX) > 0.001 || Math.abs(cameraRotationDeltaY) > 0.001) {
      camera.position.x += cameraDeltaX;
      camera.position.y += cameraDeltaY;
      camera.rotation.x += cameraRotationDeltaX;
      camera.rotation.y += cameraRotationDeltaY;
    }
  }

  dispose() {
    camera = null;

    window.removeEventListener('pointermove', onPointerMove);
  }
  
  onWindowResize() {
    camera.aspect = SceneUtils.screenWidth() / SceneUtils.screenHeight();
    camera.updateProjectionMatrix();
  }
}