import * as THREE from "three";
import { ParticleShader } from "./shaders/ParticleShader";
import { SceneUtils } from "./support/SceneUtils";
import * as Support from "./utils/hero-animation-support";
import FastSimplexNoise from "./utils/FastSimplexNoise";
import particleTextureURL1 from '../../../../assets/images/hero-animation-particle-1.png';
import particleTextureURL2 from '../../../../assets/images/hero-animation-particle-2.png';
import { ShapePoints } from "./support/ShapePoints";
import { FrameRate } from "./support/FrameRate";


// config
// Note: Any array values are for particle sets 1 and 2, respectively
const particleSizeMin = [ 0.3, 0.35 ]; // this may be scaled down based on screen size
const particleSizeMax = [ 0.6, 0.55 ]; // this may be scaled down based on screen size
const zOffset = -7; // Current Limitation: have to leave this synced to the one in Particles.js
const velocityDamping = 0.02; // percentage to damp a particle's velocity each frame (like friction)
const initialVelocityMin = 0.002;
const initialVelocityMax = 0.009;
const velocityMax = [ 0.012, 0.013 ];
const targetAttractionStrength = 0.0035;
const velocityMaxMultiplierDuringTargetAttraction = 2;
let velocityZ = 0.7; // will be scaled down on smaller devices
let mouseAttractionStrength = 0.9; // will be scaled down on smaller devices
const initialZOffsetMin = -6;
const initialZOffsetMax = 4;
let noiseJitterStrength = [ 10, 5 ]; // will be scaled down on smaller devices
let noiseJitterGlobalScale = 0.2; // will be scaled down on smaller devices
const randomPositionOffsetRadius = 1;
const randomVelocityAmount = 0.01;
const lifespanMin = 200;
const lifespanMax = 600;
const lifeExtensionWhenSettingNewTargetPoints = 450;
const fadeInSpeed = 0.002;
const fadeOutSpeed = 0.002;
const numInitialParticles = SceneUtils.isTouchDevice() ? [ 1000, 500 ] : [ 1600, 800 ]; // NOTE: can't be more than the number of points in ShapePoints
const maxParticles = 10000;
const maxParticleOpacity = [ 0.75, 1.0 ];
const particleColor = [ 0xFFFFFF, 0xDA437E ];
let shapeScale = 0.95; // will be scaled up on smaller devices
const secondsBeforeFlyOff = 5;
const lifeExtensionWhenDoingFlyOff = 250;


// private vars
let geometry1, geometry2, material1, material2;
let pointsObject1, pointsObject2;
let particleTexture1, particleTexture2;
let time;
const particles1 = [];
const particles2 = [];
const perlinNoise = new FastSimplexNoise({ frequency: noiseJitterGlobalScale });
let mouseVector = new THREE.Vector3( 0.1, 0.1, velocityZ );
let maxRadiusFromCenterX = 2; // this will be set accurately as soon as the first accurate resize event happens
let maxRadiusFromCenterY = 3.7; // this will be set accurately as soon as the first accurate resize event happens
let hasSpawnedInitialParticles = false;
let numParticlesToSpawnOnNextUpdate = [ 0, 0 ];
let currentShapeIndex = 0;
let currentLifeExtension = 0;
let flyOffTimeoutID;


// private methods
function updateParticles( geometry, particles, particleSetIndex ) {
  
  let positions = geometry.attributes.position.array;
  let alphas = geometry.attributes.alpha.array;
  let sizes = geometry.attributes.size.array;
  let speedFactor = FrameRate.speedFactor;

  // update any potential life extension
  if ( currentLifeExtension > 0 ) currentLifeExtension -= speedFactor;

  // update each particle
  for ( var i = 0; i < particles.length; i++ ) {
    
    // get each particle
    let particle = particles[ i ];
    
    // update each particle's age if we don't have a life extension going on
    if ( currentLifeExtension <= 0 ) particle.age += speedFactor;

    // update the particle's acceleration based on its target attraction
    let accelerationVector = particle.targetPosition.clone()
      .sub( particle.position )
      .multiplyScalar( particle.targetAttractionStrength * targetAttractionStrength );

    // update the particle's velocity based on its acceleration and the mouse position
    particle.velocity.add( accelerationVector );
    if ( particle.affectedByMouse ) particle.velocity.add( mouseVector );

    // damp the particle's velocity
    let particleVelocityMax = velocityMax[ particleSetIndex - 1 ];
    if ( !particle.affectedByMouse ) particleVelocityMax *= velocityMaxMultiplierDuringTargetAttraction;

    particle.velocity
      .multiplyScalar( Math.pow( 1 - velocityDamping, speedFactor ))
      .clampLength( 0, particleVelocityMax );
    
    // move the particle based on its velocity
    particle.position.add( particle.velocity.clone().multiplyScalar( speedFactor ) );
    
    // also move it with perlin noise
    particle.position.x += perlinNoise.get2DNoise(
      time / 100,
      particle.position.y * noiseJitterStrength[ particleSetIndex - 1 ], 
    ) * ( particle.noiseSpeedFactor / 50 ) * speedFactor;
    
    particle.position.y += perlinNoise.get2DNoise(
      particle.position.x * noiseJitterStrength[ particleSetIndex - 1 ], 
      time / 100,
    ) * ( particle.noiseSpeedFactor / 50 ) * speedFactor;
    
    // set the new position
    positions[ i * 3 ] = particle.position.x;
    positions[ i * 3 + 1 ] = particle.position.y;
    positions[ i * 3 + 2 ] = particle.position.z;
    
    // set the size of the particle (only because we might have removed particles,
    // so now they're in a different order and we need to keep the geometry in sync
    // with our array)
    sizes[ i ] = particle.size;
    
    // if the particle is not past its lifespan, and it's still fading in, fade it in
    if ( particle.age < particle.lifespan && particle.alpha < maxParticleOpacity[ particleSetIndex - 1 ] ) particle.alpha += fadeInSpeed * speedFactor;
    
    // if the particle is past its lifespan, fade it out
    if ( particle.age >= particle.lifespan ) particle.alpha -= fadeOutSpeed * speedFactor;
    
    // set the alpha
    alphas[ i ] = particle.alpha;
      
    // if a particle is invisible, remove it
    if ( particle.alpha <= 0 ) {
      numParticlesToSpawnOnNextUpdate[ particleSetIndex - 1 ]++;
      particles.splice( i, 1 );
      i--;
    }
  }
  
  geometry.attributes.position.needsUpdate = true;
  geometry.attributes.size.needsUpdate = true;
  geometry.attributes.alpha.needsUpdate = true;
  geometry.setDrawRange( 0, particles.length );
}


function updateParticleSpawner( geometry, particles, particleSetIndex ) {

  while ( numParticlesToSpawnOnNextUpdate[ particleSetIndex - 1 ] > 0 ) {
    spawnNewParticles( geometry, particles, particleSetIndex, 1 );
    numParticlesToSpawnOnNextUpdate[ particleSetIndex - 1 ]--;
  }
}


function spawnNewParticles( geometry, particles, particleSetIndex, numParticlesToSpawn, currentAge = 0 ) {

  for (var i = 0; i < numParticlesToSpawn; i++) {
    var position = getRandomPointOnScreen( 1.3 );
    var velocity = new THREE.Vector3( Support.getRandomNumberInRange(-1, 1), Support.getRandomNumberInRange( -1, 1 ), 0 );
    var targetPosition = getRandomPointOnScreen( 1.3 );
    var targetAttractionStrength = Support.getRandomNumberInRange( 0.5, 1 );

    createParticle( geometry, particles, particleSetIndex, position, velocity, targetPosition, targetAttractionStrength, currentAge );
  }
}


function createParticle( geometry, particles, particleSetIndex, position, velocity, targetPosition, targetAttractionStrength = 1, currentAge = 0, noiseSpeedFactor = 0.1 ) {
  
  let newParticle = { 
    position: 
      position.clone()
      .add( new THREE.Vector3(
        Support.getRandomNumberInRangeWithNormalDistribution( -randomPositionOffsetRadius, randomPositionOffsetRadius ),
        Support.getRandomNumberInRangeWithNormalDistribution( -randomPositionOffsetRadius, randomPositionOffsetRadius ),
        0,
      )), 
    velocity: 
      velocity.clone()
      .multiplyScalar( 0.5 )
      .add( new THREE.Vector3(
        Support.getRandomNumberInRange( -randomVelocityAmount, randomVelocityAmount ),
        Support.getRandomNumberInRange( -randomVelocityAmount, randomVelocityAmount ),
        0,
      ))
      .clampLength( initialVelocityMin, initialVelocityMax ), 
    targetPosition: targetPosition.clone(),
    targetAttractionStrength: targetAttractionStrength,
    affectedByMouse: true, 
    noiseSpeedFactor: noiseSpeedFactor,
    size: Support.getRandomNumberInRange( particleSizeMin[ particleSetIndex - 1 ], particleSizeMax[ particleSetIndex - 1 ] ), // or could do it based on velocity: Math.min( velocity.length(), 0.5 ).map( 0, 0.5, particleSizeMin, particleSizeMax ),
    alpha: 0.0,
    age: 0,
    lifespan: Support.getRandomNumberInRange( lifespanMin, lifespanMax ) - currentAge,
    doFlyOff: false,
    hasStartedFlyOff: false,
  };
  
  particles.push( newParticle );
  if ( particles.length >= maxParticles ) particles.pop();
  let i = particles.length - 1;
  
  let positions = geometry.attributes.position.array;
  positions[ i * 3 ] = newParticle.position.x;
  positions[ i * 3 + 1 ] = newParticle.position.y;
  positions[ i * 3 + 2 ] = newParticle.position.z;
  
  let sizes = geometry.attributes.size.array;
  sizes[ i ] = newParticle.size;
  
  let alphas = geometry.attributes.alpha.array;
  alphas[ i ] = newParticle.alpha;
  
  geometry.attributes.position.needsUpdate = true;
  geometry.attributes.size.needsUpdate = true;
  geometry.attributes.alpha.needsUpdate = true;
  
  geometry.setDrawRange( 0, i + 1 );
}


function startFlyOff() {

  for ( var particleSetIndex = 1; particleSetIndex <= 2; particleSetIndex++) {
    var particles = particleSetIndex === 1 ? particles1 : particles2;

    for ( var i = 0; i < particles.length; i++ ) {
      var particle = particles[i];

      if ( particle.doFlyOff && !particle.hasStartedFlyOff ) {
        particle.hasStartedFlyOff = true;
        particle.age -= lifeExtensionWhenDoingFlyOff;
        particle.targetPosition.z += 15;
      }
    }
  }
}


function setNewParticleTargetPoints() {

  let maxRadius = Math.min( maxRadiusFromCenterX, maxRadiusFromCenterY ) * shapeScale;

  for ( var particleSetIndex = 1; particleSetIndex <= 2; particleSetIndex++) {
    var particles = particleSetIndex === 1 ? particles1 : particles2;

    for ( var i = 0; i < particles.length; i++ ) {
      var particle = particles[i];

      if ( !particle.hasStartedFlyOff ) {
        particle.affectedByMouse = false;
        particle.doFlyOff = true;
        particle.targetPosition = new THREE.Vector3(
          ( ShapePoints[currentShapeIndex][i][0] - 0.5 ) * maxRadius,
          ( ShapePoints[currentShapeIndex][i][1] - 0.5 ) * -maxRadius,
          zOffset + Support.getRandomNumberInRangeWithNormalDistribution( -0.5, 0.5 ),
        );
      }
    }
  }

  currentShapeIndex++;
  if ( currentShapeIndex >= ShapePoints.length ) currentShapeIndex = 0;

  currentLifeExtension = lifeExtensionWhenSettingNewTargetPoints; // extend the life of all particles when we set their new target point (so they don't immediately die and get replace with others)

  clearTimeout( flyOffTimeoutID );
  flyOffTimeoutID = setTimeout( startFlyOff, secondsBeforeFlyOff * 1000 );
}


function getRandomPointOnScreen( distanceFromCenter = 1.3 ) {
  return new THREE.Vector3( 
    Support.getRandomNumberInRangeWithNormalDistribution( -maxRadiusFromCenterX * distanceFromCenter, maxRadiusFromCenterX * distanceFromCenter ), 
    Support.getRandomNumberInRangeWithNormalDistribution( -maxRadiusFromCenterY * distanceFromCenter, maxRadiusFromCenterY * distanceFromCenter ), 
    zOffset + Support.getRandomNumberInRangeWithNormalDistribution( initialZOffsetMin, initialZOffsetMax ),
  );
}


function onPointerMove( event ) {
  let [ x, y ] = Support.getPointerXY( event );
  mouseVector.setX( x.map( 0, SceneUtils.screenWidth(), -mouseAttractionStrength, mouseAttractionStrength ));
  mouseVector.setY( y.map( 0, SceneUtils.screenHeight(), -mouseAttractionStrength, mouseAttractionStrength ));
}


function onWorldMaxXYChange( event, coordsXY, worldPosition ) {
  maxRadiusFromCenterX = worldPosition.x;
  maxRadiusFromCenterY = worldPosition.y;

  // spawn the initial particles here, to allow for the world size to be set
  if ( !hasSpawnedInitialParticles ) {
    hasSpawnedInitialParticles = true;
    spawnNewParticles( geometry1, particles1, 1, numInitialParticles[0], lifespanMin * 0.98 );
    spawnNewParticles( geometry2, particles2, 2, numInitialParticles[1], lifespanMin * 0.98 );
  }
}



// class
export class Particles {
  
  constructor() {
    
    // init variables
    time = 0;
    const positions1 = new Float32Array( maxParticles * 3 );
    const positions2 = new Float32Array( maxParticles * 3 );
    const sizes1 = new Float32Array( maxParticles );
    const sizes2 = new Float32Array( maxParticles );
    const alphas1 = new Float32Array( maxParticles );
    const alphas2 = new Float32Array( maxParticles );
    
    // change some config based on screen size
    let scaleValue = Math.pow( Math.min( SceneUtils.screenWidth() / 1000, 1 ), 1/3 );
    particleSizeMin[ 0 ] *= scaleValue;
    particleSizeMin[ 1 ] *= scaleValue;
    particleSizeMax[ 0 ] *= scaleValue;
    particleSizeMax[ 1 ] *= scaleValue;

    if ( SceneUtils.screenWidth() < 1000 ) {
      shapeScale *= 1.3;
      velocityZ *= 0.6;
      mouseAttractionStrength *= 0.6;
      noiseJitterStrength[ 0 ] *= 0.5;
      noiseJitterStrength[ 1 ] *= 0.5;
    }
    
    // create the particle system
    geometry1 = new THREE.BufferGeometry();
    geometry2 = new THREE.BufferGeometry();
    
    positions1[ 0 ] = -12.0; // create a very first point so we don't throw an error
    positions1[ 1 ] = -12.0;
    positions1[ 2 ] = -7.0;
    sizes1[ 0 ] = 1.0;
    alphas1[ 0 ] = 0.0;

    positions2[ 0 ] = -12.0; // create a very first point so we don't throw an error
    positions2[ 1 ] = -12.0;
    positions2[ 2 ] = -7.0;
    sizes2[ 0 ] = 1.0;
    alphas2[ 0 ] = 0.0;
    
    geometry1.setAttribute( 'position', new THREE.BufferAttribute( positions1, 3 ));
    geometry1.setAttribute( 'size', new THREE.BufferAttribute( sizes1, 1 ));
    geometry1.setAttribute( 'alpha', new THREE.BufferAttribute( alphas1, 1 ));
    
    geometry1.setDrawRange( 0, 0 );

    geometry2.setAttribute( 'position', new THREE.BufferAttribute( positions2, 3 ));
    geometry2.setAttribute( 'size', new THREE.BufferAttribute( sizes2, 1 ));
    geometry2.setAttribute( 'alpha', new THREE.BufferAttribute( alphas2, 1 ));
    
    geometry2.setDrawRange( 0, 0 );

    particleTexture1 = new THREE.TextureLoader().load( particleTextureURL1 );
    particleTexture2 = new THREE.TextureLoader().load( particleTextureURL2 );
    
    material1 = new THREE.ShaderMaterial({
      uniforms: {
				color: { value: new THREE.Color( particleColor[0] )},
				pointTexture: { value: particleTexture1 },
			},
      vertexShader: ParticleShader.vertexShader,
      fragmentShader: ParticleShader.fragmentShader,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      transparent: true,
    });

    material2 = new THREE.ShaderMaterial({
      uniforms: {
				color: { value: new THREE.Color( particleColor[1] )},
				pointTexture: { value: particleTexture2 },
			},
      vertexShader: ParticleShader.vertexShader,
      fragmentShader: ParticleShader.fragmentShader,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      transparent: true,
    });
    
    pointsObject1 = new THREE.Points( geometry1, material1 );
    pointsObject2 = new THREE.Points( geometry2, material2 );
    
    // add it to the scene
    SceneUtils.scene.add( pointsObject1 );
    SceneUtils.scene.add( pointsObject2 );
    
    // listen for pointer move events
    window.addEventListener( 'pointermove', onPointerMove );
    // SceneUtils.registerMouseWorldCoordinatesListener( onPointerMove, zOffset );
    
    // listen for resize events to know the world bounds
    SceneUtils.registerWorldMaxXYListener( onWorldMaxXYChange, zOffset );
  }
  
  
  update() {

    // increase time
    time++;
    
    // update the particles
    updateParticles( geometry1, particles1, 1 );
    updateParticles( geometry2, particles2, 2 );
    
    // update the particle spawner
    updateParticleSpawner( geometry1, particles1, 1 );
    updateParticleSpawner( geometry2, particles2, 2 );
  }


  dispose() {
    geometry1.dispose();
    geometry1 = null;

    geometry2.dispose();
    geometry2 = null;

    material1.dispose();
    material1 = null;

    material2.dispose();
    material2 = null;

    pointsObject1 = null;

    pointsObject2 = null;

    particles1.length = 0;
    particles2.length = 0;

    particleTexture1.dispose();
    particleTexture1 = null;

    particleTexture2.dispose();
    particleTexture2 = null;

    hasSpawnedInitialParticles = false;

    clearTimeout( flyOffTimeoutID );

    window.removeEventListener( 'pointermove', onPointerMove );
  }


  doNextInteraction() {
    setNewParticleTargetPoints();
  }
  
  
  // onWindowResize() {
  // }
}