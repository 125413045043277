import * as THREE from "three";
import { SceneUtils } from "./support/SceneUtils";
import anime from "animejs";


// config
const zOffsetPlane = -8;
const zOffsetLight = -5;
const lightIntensity = 2.5;
const lightPositionY = 0.4;
const materialShininess = 20;
const pinkColor = 0xDA437E;
// const goldColor = 0xFFC532;
// const aquaColor = 0x58C2B7;
// const violetColor = 0x9162C0;
// const blueColor = 0x2B7EA1;


// private vars
let material, light, planeGeometry, plane;
const interactionFunctions = [ doSlowPulse, doFastPulse, doBeaconPulse, doLightBulbShine, doHeartBeat ];
let interactionFunctionIndex = -1;


// private methods
function doHeartBeat() {
  const initialDuration = 3000;
  const transitionDuration = 75;
  const betweenBeatDuration = 400;
  const endDuration = 3000;
  const easing = "easeInOutSine";
  // const startingLightColor = light.color.clone();
  // const endingLightColor = new THREE.Color( pinkColor );

  anime({
    targets: [ light.position ],
    z: [
      { value: zOffsetLight, duration: initialDuration },
      { value: "+=1.5", duration: transitionDuration },
      { value: "-=1.5", duration: transitionDuration * 3 },
      { value: "+=1.5", duration: transitionDuration },
      { value: "-=1.5", duration: transitionDuration * 3 },
      { value: "-=1", duration: betweenBeatDuration },
      { value: "+=2.5", duration: transitionDuration },
      { value: "-=1.5", duration: transitionDuration * 3 },
      { value: "+=1.5", duration: transitionDuration },
      { value: "-=1.5", duration: transitionDuration * 3 },
      { value: "-=1", duration: endDuration * 0.5 },
      { value: "+=2.5", duration: endDuration * 1 },
    ],
    easing: easing,
  });

  anime({
    targets: [ light ],
    intensity: [
      { value: lightIntensity, duration: initialDuration },
      { value: "+=0.25", duration: transitionDuration },
      { value: "-=0.25", duration: transitionDuration * 3 },
      { value: "+=0.25", duration: transitionDuration },
      { value: "-=0.25", duration: transitionDuration * 3 },
      { value: "-=0.1", duration: betweenBeatDuration },
      { value: "+=0.35", duration: transitionDuration },
      { value: "-=0.25", duration: transitionDuration * 3 },
      { value: "+=0.25", duration: transitionDuration },
      { value: "-=0.25", duration: transitionDuration * 3 },
      { value: "-=0.1", duration: betweenBeatDuration },
      { value: 0, duration: endDuration * 1.5 },
    ],
    easing: easing,
    // update: (anim) => {
    //   light.color.lerpColors( startingLightColor, endingLightColor, Math.min( 1, 2.5 * anim.progress / 100 ));
    // },
  });

  anime({
    targets: [ material ],
    shininess: [
      { value: materialShininess, duration: initialDuration },
      { value: "+=8", duration: transitionDuration },
      { value: "-=8", duration: transitionDuration * 3 },
      { value: "+=8", duration: transitionDuration },
      { value: "-=8", duration: transitionDuration * 3, endDelay: betweenBeatDuration },
      { value: "+=8", duration: transitionDuration },
      { value: "-=8", duration: transitionDuration * 3 },
      { value: "+=8", duration: transitionDuration },
      { value: "-=8", duration: transitionDuration * 3, endDelay: betweenBeatDuration },
      { value: materialShininess, duration: endDuration },
    ],
    easing: easing,
  });
}


function doLightBulbShine() {
  const initialDuration = 2000;
  const transitionInDuration = 1700;
  const transitionOutDuration = 2000;
  const shineDuration = 1000;
  const endDuration = 2000;
  const easing = "easeInOutSine";
  // const startingLightColor = light.color.clone();
  // const endingLightColor = new THREE.Color( goldColor );

  anime({
    targets: [ light.position ],
    z: [
      { value: zOffsetLight - 1, duration: initialDuration },
      { value: "+=3", duration: transitionInDuration, endDelay: shineDuration },
      { value: "-=2", duration: transitionOutDuration },
      { value: "-=1.5", duration: endDuration },
    ],
    easing: easing,
  });

  anime({
    targets: [ light ],
    intensity: [
      { value: lightIntensity - 0.5, duration: initialDuration },
      { value: "+=1.0", duration: transitionInDuration, endDelay: shineDuration },
      { value: "-=0.8", duration: transitionOutDuration },
      { value: 0, duration: endDuration, easing: "easeOutQuad" },
    ],
    easing: easing,
    // update: (anim) => {
    //   light.color.lerpColors( startingLightColor, endingLightColor, Math.min( 1, 1.5 * anim.progress / 100 ));
    // },
  });

  anime({
    targets: [ material ],
    shininess: [
      { value: materialShininess, duration: initialDuration },
      { value: "+=10", duration: transitionInDuration, endDelay: shineDuration },
      { value: "-=10", duration: transitionOutDuration },
    ],
    easing: easing,
  });
}


function doSlowPulse() {
  const initialDuration = 1800;
  const transitionDuration = 1100;
  const endDuration = 4000;
  const easing = "easeInOutSine";
  // const startingLightColor = light.color.clone();
  // const endingLightColor = new THREE.Color( aquaColor );

  anime({
    targets: [ light.position ],
    z: [
      { value: zOffsetLight - 1, duration: initialDuration },
    ],
    easing: easing,
  }).finished.then(() => {
    anime({
      targets: [ light.position ],
      z: [
        { value: "+=2.6", duration: transitionDuration },
      ],
      easing: easing,
      direction: "alternate",
      loop: 4,
    });
  });

  anime({
    targets: [ light ],
    intensity: [
      { value: lightIntensity - 0.8, duration: initialDuration },
    ],
    easing: easing,
    // update: (anim) => {
    //   light.color.lerpColors( startingLightColor, endingLightColor, Math.min( 1, anim.progress / 100 ));
    // },
  }).finished.then(() => {
    anime({
      targets: [ light ],
      intensity: [
        { value: "+=0.9", duration: transitionDuration },
      ],
      easing: easing,
      direction: "alternate",
      loop: 4,
    }).finished.then(() => {
      anime({
        targets: [ light ],
        intensity: [
          { value: 0, duration: endDuration },
        ],
        easing: "easeOutQuad",
      });
    });
  });

  anime({
    targets: [ material ],
    shininess: [
      { value: materialShininess, duration: initialDuration },
    ],
    easing: easing,
  }).finished.then(() => {
    anime({
      targets: [ material ],
      shininess: [
        { value: "+=5", duration: transitionDuration },
      ],
      easing: easing,
      direction: "alternate",
      loop: 4,
    });
  });
}


function doBeaconPulse() {
  const initialDuration = 2200;
  const transitionInDuration = 200;
  const transitionOutDuration = 1700;
  const endDuration = 4000;
  const easing = "easeInOutSine";
  // const startingLightColor = light.color.clone();
  // const endingLightColor = new THREE.Color( violetColor );

  anime({
    targets: [ light.position ],
    z: [
      { value: zOffsetLight - 1, duration: initialDuration },
    ],
    easing: easing,
  }).finished.then(() => {
    anime({
      targets: [ light.position ],
      z: [
        { value: "+=1.5", duration: transitionInDuration },
        { value: "-=1.5", duration: transitionOutDuration },
      ],
      easing: easing,
      loop: 2,
    });
  });

  anime({
    targets: [ light ],
    intensity: [
      { value: lightIntensity - 0.8, duration: initialDuration },
    ],
    easing: easing,
    // update: (anim) => {
    //   light.color.lerpColors( startingLightColor, endingLightColor, Math.min( 1, anim.progress / 100 ));
    // },
  }).finished.then(() => {
    anime({
      targets: [ light ],
      intensity: [
        { value: "+=0.8", duration: transitionInDuration },
        { value: "-=0.8", duration: transitionOutDuration },
      ],
      easing: easing,
      loop: 2,
    }).finished.then(() => {
      anime({
        targets: [ light ],
        intensity: [
          { value: 0, duration: endDuration },
        ],
        easing: "easeOutQuad",
      });
    });
  });

  anime({
    targets: [ material ],
    shininess: [
      { value: materialShininess, duration: initialDuration },
    ],
    easing: easing,
  }).finished.then(() => {
    anime({
      targets: [ material ],
      shininess: [
        { value: "+=7", duration: transitionInDuration },
        { value: "-=7", duration: transitionOutDuration },
      ],
      easing: easing,
      loop: 2,
    });
  });
}


function doFastPulse() {
  const initialDuration = 2500;
  const transitionInDuration = 20;
  const transitionOutDuration = 1800;
  const easing = "easeOutCirc";
  // const startingLightColor = light.color.clone();
  // const endingLightColor = new THREE.Color( blueColor );

  anime({
    targets: [ light.position ],
    z: [
      { value: zOffsetLight - 2, duration: initialDuration },
    ],
    easing: easing,
  }).finished.then(() => {
    anime({
      targets: [ light.position ],
      z: [
        { value: zOffsetLight - 3, duration: transitionInDuration },
        { value: "+=6", duration: transitionOutDuration },
      ],
      easing: easing,
      loop: 2,
    });
  });

  anime({
    targets: [ light ],
    intensity: [
      { value: 0, duration: initialDuration },
    ],
    easing: easing,
    // update: (anim) => {
    //   light.color.lerpColors( startingLightColor, endingLightColor, Math.min( 1, anim.progress / 100 ));
    // },
  }).finished.then(() => {
    anime({
      targets: [ light ],
      intensity: [
        { value: lightIntensity + 1.5, duration: transitionInDuration },
        { value: 0, duration: transitionOutDuration },
      ],
      easing: easing,
      loop: 2,
    });
  });

  anime({
    targets: [ material ],
    shininess: [
      { value: materialShininess, duration: initialDuration },
    ],
    easing: easing,
  }).finished.then(() => {
    anime({
      targets: [ material ],
      shininess: [
        { value: "+=10", duration: transitionInDuration },
        { value: "-=10", duration: transitionOutDuration },
      ],
      easing: easing,
      loop: 2,
    });
  });
}


function clearExistingAnimations() {
  anime.remove( light );
  anime.remove( light.position );
  anime.remove( material );
}



// class
export class BackgroundGlow {
  
    constructor() {
      
      // init variables
      
      // add a plane to shine a light on
      material = new THREE.MeshPhongMaterial({
        color: new THREE.Color( 0x000000 ),
        shininess: materialShininess,
        side: THREE.DoubleSide,
        depthWrite: false,
      });

      planeGeometry = new THREE.PlaneGeometry( 15, 15 );
      planeGeometry.applyMatrix4(new THREE.Matrix4().fromArray([ 15,0,0,0,0,15,0,0,0,0,1,0,0,0,zOffsetPlane,1 ]));

      plane = new THREE.Mesh( planeGeometry, material );
      SceneUtils.scene.add( plane );

      // add a light
      light = new THREE.PointLight( pinkColor, 0, 100 );
      light.position.set( 0, lightPositionY, zOffsetPlane + 0.5 );
      SceneUtils.scene.add( light );
    }
    
    
    update() {
      
    }


    dispose() {
      anime.remove( light );
      anime.remove( light.position );
      anime.remove( material );

      material.dispose();
      material = null;

      light.dispose();
      light = null;

      planeGeometry.dispose();
      planeGeometry = null;

      plane = null;
    }


    doNextInteraction() {
      clearExistingAnimations();

      interactionFunctionIndex++;
      if ( interactionFunctionIndex >= interactionFunctions.length ) interactionFunctionIndex = 0;

      interactionFunctions[ interactionFunctionIndex ]();
    }
    
    
    // onWindowResize() {
    // }
  }