import * as THREE from "three";
import { FrameRate } from "./support/FrameRate";
import { SceneUtils } from "./support/SceneUtils";
import * as Support from "./utils/hero-animation-support";


// config
let particleSizeMin = 0.008; // will be scaled down on smaller devices
let particleSizeMax = 0.03; // will be scaled down on smaller devices
const zOffset = -7; // Current Limitation: have to leave this synced to the one in Particles.js
const numParticles = 200;
const rotationSpeedMax = 0.01;
const particleVelocityYMin = 0.002;
const particleVelocityYMax = 0.005;
const particleBaseColor = 0x58C2B7;
const particleShineColor = 0x58C2B7;
const lightColor = 0x58C2B7;


// private vars
let material, light, instancedMesh;
const particles = new Array( numParticles );
const dummyObj = new THREE.Object3D();
let maxRadiusFromCenterX = 2; // this will be set accurately as soon as the first accurate resize event happens
let maxRadiusFromCenterY = 3.7; // this will be set accurately as soon as the first accurate resize event happens
let hasSpawnedInitialParticles = false;


// private methods
function updateParticles() {
  
  let particle;
  let speedFactor = FrameRate.speedFactor;
  
  for ( var i = 0; i < particles.length; i++ ) {
    
    // get each particle
    particle = particles[ i ];
    
    // move the particle based on its velocity
    particle.position.y += ( particle.velocityY * speedFactor );

    // if the particle is off the screen at the top, put it at the bottom
    if ( particle.position.y > maxRadiusFromCenterY * 2 ) particle.position.y = -maxRadiusFromCenterY * 2;

    // rotate the particle
    particle.rotation.add( particle.rotationVelocity );
    
    // update the dummy obj
    dummyObj.position.copy( particle.position );
    dummyObj.scale.copy( particle.scale );
    dummyObj.rotation.setFromVector3( particle.rotation );
    dummyObj.updateMatrix();

    // apply the dummy obj's matrix to the instanced item
    instancedMesh.setMatrixAt( i, dummyObj.matrix );
  }
  
  instancedMesh.instanceMatrix.needsUpdate = true;
}


function createParticles() {

  let particle;

  for ( var i = 0; i < particles.length; i++ ) {

    let randomScale = Support.getRandomNumberInRange( particleSizeMin, particleSizeMax );

    particle = {
      position: getRandomPointOnScreen( 3 ),
      velocityY: Support.getRandomNumberInRange( particleVelocityYMin, particleVelocityYMax ),
      scale: new THREE.Vector3( randomScale, randomScale, randomScale ),
      rotation: new THREE.Vector3( 
        Support.getRandomNumberInRange( 0, 6.28 ),
        Support.getRandomNumberInRange( 0, 6.28 ),
        Support.getRandomNumberInRange( 0, 6.28 ),
      ),
      rotationVelocity: new THREE.Vector3(
        Support.getRandomNumberInRange( -rotationSpeedMax, rotationSpeedMax ),
        Support.getRandomNumberInRange( -rotationSpeedMax, rotationSpeedMax ),
        Support.getRandomNumberInRange( -rotationSpeedMax, rotationSpeedMax ),
      ),
    };

    particles[ i ] = particle;
  }

}


function getRandomPointOnScreen( distanceFromCenter = 1.3 ) {
  return new THREE.Vector3( 
    Support.getRandomNumberInRangeWithNormalDistribution( -maxRadiusFromCenterX * distanceFromCenter, maxRadiusFromCenterX * distanceFromCenter ), 
    Support.getRandomNumberInRangeWithNormalDistribution( -maxRadiusFromCenterY * distanceFromCenter, maxRadiusFromCenterY * distanceFromCenter ), 
    zOffset + Support.getRandomNumberInRangeWithNormalDistribution( -12, 3 ),
  );
}


function onWorldMaxXYChange( event, coordsXY, worldPosition ) {
  maxRadiusFromCenterX = worldPosition.x;
  maxRadiusFromCenterY = worldPosition.y;
  
  // create the initial particles here, to allow for the world size to be set
  if ( !hasSpawnedInitialParticles ) {
    hasSpawnedInitialParticles = true;
    createParticles();
  }
}



// class
export class Dust {
  
  constructor() {

    // scale down the size of particles on smaller devices
    if ( SceneUtils.screenWidth() < 1000 ) {
      // particleSizeMin *= 0.75;
      particleSizeMax *= 0.75;
    }

    // create the material
    material = new THREE.MeshPhongMaterial({
      color: particleBaseColor,
      specular: particleShineColor,
      shininess: 100,
      depthWrite: false,
    });
    
    // create the particle system
    instancedMesh = new THREE.InstancedMesh( new THREE.DodecahedronGeometry(), material, numParticles );

    // add it to the scene
    SceneUtils.scene.add( instancedMesh );

    // add a light
    light = new THREE.PointLight( lightColor, 100, 100 );
    light.position.set( 0, 0, zOffset - 10 );
    SceneUtils.scene.add( light );

    // listen for pointer move events
    // window.addEventListener( 'pointermove', onPointerMove );
    
    // listen for resize events to know the world bounds
    SceneUtils.registerWorldMaxXYListener( onWorldMaxXYChange, zOffset );
  }
  
  
  update() {

    // update the particles
    if ( hasSpawnedInitialParticles ) updateParticles();
  }


  dispose() {
    material.dispose();
    material = null;

    light.dispose();
    light = null;

    instancedMesh.dispose();
    instancedMesh = null;

    hasSpawnedInitialParticles = false;

    // window.removeEventListener( 'pointermove', onPointerMove );
  }

  
  // onWindowResize() {
  // }
}