import * as THREE from "three";
import * as Support from "../utils/hero-animation-support";

// private vars
let scene, canvas, camera, renderer; // ideally these would be public static vars, but at the time of coding, Safari (iOS and Desktop) does not support this!
let targetPlane;
let mouseWorldCoordinatesCallbacks = [];
let worldMaxXYCallbacks = [];
let mouseMoveZOffset, worldMaxZOffset;
let arePointerListenersActive = true;
const raycaster = new THREE.Raycaster();


// private methods
function createTargetPlane() {
  
  // only allow initialization once 
  if ( targetPlane !== undefined ) return;
  
  // create a target plane (for determining mouse position in 3D space)
  const targetPlaneGeometry = new THREE.PlaneGeometry( 70, 30 );
  const targetPlaneMaterial = new THREE.MeshBasicMaterial();
  
  targetPlane = new THREE.Mesh( targetPlaneGeometry, targetPlaneMaterial );
  targetPlane.visible = false;
  
  scene.add(targetPlane);
}


function initScreenToWorldMouseCalculation( zOffset ) {
  
  // only allow initialization once
  // (NOTE: This currently allows only one zOffset to be used for this type of
  // listener. This could be updated in the future to keep an array of them).
  if ( mouseMoveZOffset || mouseMoveZOffset === 0 ) return;
  
  // store the z offset for the future
  mouseMoveZOffset = zOffset;
  
  // listen for pointer move
  window.addEventListener( 'pointermove', calculateMouseWorldCoords );
}


function initWorldMaxXYCalculation( zOffset ) {
  
  // only allow initialization once
  // (NOTE: This currently allows only one zOffset to be used for this type of
  // listener. This could be updated in the future to keep an array of them).
  if ( worldMaxZOffset !== undefined ) return;
  
  // store the z offset for the future
  worldMaxZOffset = zOffset;
}


function calculateMouseWorldCoords( event ) {
  
  // if pointer listeners aren't active, quit
  if ( !arePointerListenersActive) return;
  
  // get the pointer's page coordinates
  var [ x, y ] = Support.getPointerXY( event );
  
  // offset them by the window's scroll position and the canvas's position 
  // on the page (in case it's not always fixed at 0, 0)
  var canvasRect = canvas.getBoundingClientRect();
  x -= window.scrollX + canvasRect.left;
  y -= window.scrollY + canvasRect.top;
  
  // convert them to world coords
  var worldPosition = SceneUtils.convertScreenCoordsToWorldCoords( x, y, mouseMoveZOffset );
  
  // also include the screen size
  var screenSize = [ SceneUtils.screenWidth(), SceneUtils.screenHeight() ];
  
  // call the callbacks
  mouseWorldCoordinatesCallbacks.forEach( callback => callback( event, [ x, y ], worldPosition, screenSize ));
}


function calculateWorldMaxXY( event ) {
  
  // if nothing is registered to listen to this, just quit
  if ( worldMaxZOffset === undefined ) return;
  
  // get the world max coords
  var worldPosition = SceneUtils.getWorldMaxXY( worldMaxZOffset );
  
  // if the coords are 0, 0, try again in a moment (this may happen at the
  // very beginning of rendering)
  if ( worldPosition.x === 0 && worldPosition.y === 0 ) {
    
    setTimeout( () => { calculateWorldMaxXY( event ) }, 33 );
    
  // else, call the callbacks
  } else {
    
    worldMaxXYCallbacks.forEach( callback => callback( event, [ SceneUtils.screenWidth(), 0 ], worldPosition ));
  }
}



// class
export class SceneUtils {
  
  // public static methods
  static init( _scene, _canvas, _renderer ) {
    // camera can't be passed here, because it relies on this library to be initialized first!
    // SceneUtils.camera should be set immediately after the camera is created
    scene = _scene;
    canvas = _canvas;
    renderer = _renderer;
  }

  static disposeAll() {
    if ( targetPlane && targetPlane.dispose ) targetPlane.dispose();

    scene = null;
    canvas = null;
    camera = null;

    renderer.renderLists.dispose();
    renderer.dispose();
    renderer = null;

    window.removeEventListener( 'pointermove', calculateMouseWorldCoords );

    mouseMoveZOffset = null;
    mouseWorldCoordinatesCallbacks.length = 0;
    worldMaxXYCallbacks.length = 0;
  }
  
  static isTouchDevice() {  
    return (
      typeof window !== "undefined" && (
        ( 'ontouchstart' in window ) || 
        ( navigator.maxTouchPoints > 0 ) ||  
        ( navigator.msMaxTouchPoints > 0 )
      )
    );
  };

  static screenWidth() {
    return canvas.clientWidth;
  }
  
  static screenHeight() {
    return canvas.clientHeight;
  }
  
  static screenAspectRatio() {
    return this.screenWidth() / this.screenHeight();
  }
  
  static totalScreenSize() {
    return this.screenWidth() * this.screenHeight();
  }
  
  static normalizedDeviceXY( coordsXY ) {
    var [ x, y ] = coordsXY;
    return new THREE.Vector2(
      ( x / this.screenWidth()) * 2 - 1, 
      -( y / this.screenHeight()) * 2 + 1
    );
  }
  
  static convertScreenCoordsToWorldCoords( x, y, zOffset = -7 ) {
    
    // create the target plane (if it doesn't exist already)
    createTargetPlane();
    
    // set the targetPlane's z coord
    if ( targetPlane.position.z !== zOffset ) targetPlane.position.setZ( zOffset );
    
    // use the raycaster to figure out where to put the target (under the person's touch/mouse)
    raycaster.setFromCamera( this.normalizedDeviceXY([ x, y ]), camera );
    
    var intersections = raycaster.intersectObject( targetPlane );
    if ( !intersections || !intersections[0]?.point ) return;
    
    var worldPosition = intersections[0].point;
    
    // return the world coords
    return worldPosition;
  }
  
  static getWorldMaxXY( zOffset = -7 ) {
    
    // get the max screen x
    var x = this.screenWidth();
    
    // convert it to world coords
    return this.convertScreenCoordsToWorldCoords( x, 0, zOffset );
  }
  
  static registerMouseWorldCoordinatesListener( callback, zOffset = -7 ) {
    initScreenToWorldMouseCalculation( zOffset );
    mouseWorldCoordinatesCallbacks.push( callback );
  }
  
  static registerWorldMaxXYListener( callback, zOffset = -7 ) {
    initWorldMaxXYCalculation( zOffset );
    worldMaxXYCallbacks.push( callback );
  }
  
  static pausePointerListeners() {
    arePointerListenersActive = false;
  }
  
  static resumePointerListeners() {
    arePointerListenersActive = true;
  }
  
  static onWindowResize( event ) {
    calculateWorldMaxXY( event );
  }
  
  
  // public static variables
  static get scene() {
    return scene;
  }
  
  static get canvas() {
    return canvas;
  }
  
  static get camera() {
    return camera;
  }
  
  static set camera( _camera ) {
    camera = _camera;
  }
  
  static get renderer() {
    return renderer;
  }
}