import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
// import ScrollPercentageObserver from '../../ScrollPercentageObserver/ScrollPercentageObserver'
// import mobileBg from '../../../assets/images/bg-mobile-clinical-intel.png'
import * as styles from './SolutionItem.module.scss'
// import { StaticImage } from 'gatsby-plugin-image'
import RightArrow from '../../svg/RightArrow'

export default function SolutionItem(props) {
  const { hoverBgImage, setHoverBgImage } = props
  const { id, linkText, link = '/', subtitle, color = 'aqua' } = props.solution

  // using state for hover because logic is required
  // if another link is already hovered entering linkWrapper will trigger hover
  // if no other link is hovered entering linkText span will trigger hover
  const [isHovered, setIsHovered] = useState(false)
  const linkTextref = useRef()

  const handleMouseEnter = (e) => {
    const { left, right } = linkTextref.current.getBoundingClientRect()

    // if another link is hovered actiavte this SolutionItem

    if (hoverBgImage !== -1) {
      setHoverBgImage(id)
      setIsHovered(true)
      return
    }

    // check if mouse is over the linkText span when no other link is hovered

    if (e.clientX > left - 10 && e.clientX < right + 10) {
      setHoverBgImage(id)
      setIsHovered(true)
      return
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    setHoverBgImage(-1)
  }

  const linkWrapperClassnames = `${styles.linkWrapper} ${
    hoverBgImage !== -1 ? styles.parentHover : ''
  } ${isHovered ? styles.hover : ''}`

  const subtitleClassnames = `${styles.subtitle} ${styles[color]}`

  // Test for internal or external link and return <Link> or <a>
  // ToDo - cleanup duplicated link content below

  return (
    <>
      {/http/i.test(link) ? (
        <a
          href={link}
          className={linkWrapperClassnames}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          target="_blank"
          rel="noopener noreferrer"
        >
          
          <span
            className={styles.linkText}
            ref={linkTextref}
            onMouseEnter={handleMouseEnter}
            role='link'
            tabIndex='0'
          >
            {linkText}
          </span>
          <span className={subtitleClassnames}>{subtitle}</span>
          <RightArrow width="40" className={styles.arrow} />
        </a>
      ) : (
        <Link
          to={link}
          className={linkWrapperClassnames}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}  
        >
          <span
            className={styles.linkText}
            ref={linkTextref}
            onMouseEnter={handleMouseEnter}
            role='link'
            tabIndex='0'
          >
            {linkText}
          </span>
          <span className={subtitleClassnames}>{subtitle}</span>
          <RightArrow width="40" className={styles.arrow} />
        </Link>
      )}
    </>
  )
}
