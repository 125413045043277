import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { SceneUtils } from './support/SceneUtils';
import { FrameRate } from './support/FrameRate';
import { SceneObjects } from './support/SceneObjects';
import { Particles } from './Particles';
import { BackgroundGlow } from "./BackgroundGlow";
import { Dust } from "./Dust";
import { Camera } from './support/Camera';
import Stats from "three/examples/jsm/libs/stats.module";
import * as classes from './HeroAnimation.module.scss';



export default function HeroAnimation() {

  // config
  const secondsBeforeFirstShape = 0.6;
  const minSecondsBetweenShapes = SceneUtils.isTouchDevice() ? 2.5 : 0.1; // don't change shapes immediately on mobile, since it's touched so much more often
  const maxSecondsBetweenShapes = 9;
  const glowStrength = SceneUtils.isTouchDevice() ? 1.4 : 2;
  const glowRadius = SceneUtils.isTouchDevice() ? 0.12 : 0.2;
  const glowThreshold = 0.4;
  const slowAnimationDurationThreshold = 4; // how many seconds of slow fps is allowed before we pause the animation
  const slowAnimationFPSThreshold = 15; // what frame rate is considered slow
  const slowAnimationFirstCheckAt = 2; // how many seconds to wait after dom content loaded before we start checking the animation speed
  const showStats = false;
  
  
  // vars
  const canvasRef = useRef();
  const containerRef = useRef();
  
  
  // setup
  useEffect(() => {
    
    // init vars
    const allowRightClickPause = false;
    const canvas = canvasRef.current;
    let lastShapeTime = 0;
    let intervalTimer;
    let requestAnimationFrameID;
    let slowAnimationTimerID;
  
  
    // init the scene
    const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: false });
    const scene = new THREE.Scene();
    
    SceneUtils.init( scene, canvas, renderer ); // must be initialized before anything that relies on it
    const camera = new Camera();
    SceneUtils.camera = camera.camera;
    SceneObjects.add( camera );
    
    SceneObjects.add( new BackgroundGlow() );
    SceneObjects.add( new Dust() );
    SceneObjects.add( new Particles() );
    
    var isAnimationRunning = true;
  
  
  
    // init the post processing effects
    const renderPass = new RenderPass( scene, SceneUtils.camera );
    const bloomPass = new UnrealBloomPass( 
      new THREE.Vector2( SceneUtils.screenWidth(), SceneUtils.screenHeight() ),
      glowStrength,
      glowRadius,
      glowThreshold,
    );
  
    const composer = new EffectComposer( renderer );
    composer.addPass( renderPass );
    composer.addPass( bloomPass );
    


    // init stats
    let stats, container;

    if ( showStats ) {
      container = containerRef.current;
      stats = new Stats();
      container.appendChild( stats.dom );
    }

    
    
    // listen for events
    window.addEventListener( 'resize', onWindowResize );
    
    
    
    // start the update/animation cycle
    onWindowResize();
    animate();
    
    
    
    // handle resize
    function onWindowResize() {
      SceneUtils.onWindowResize();
      SceneObjects.onWindowResize();
      renderer.setSize( SceneUtils.screenWidth(), SceneUtils.screenHeight(), false );
      composer.setSize( SceneUtils.screenWidth(), SceneUtils.screenHeight(), false );
    }
  
  
  
    // handle pointer move
    // function handlePointerMove( event ) {
  
    //   // get the pointer's page coordinates
    //   let y = Support.getPointerClientY( event );
      
    //   // get the canvas bounding box
    //   let canvasRect = canvas.getBoundingClientRect();
  
    //   // if the pointer is near the center of the canvas, do the next pointer interaction
    //   let minY = (( canvasRect.bottom - canvasRect.top ) * 0.4 ) + canvasRect.top;
    //   let maxY = (( canvasRect.bottom - canvasRect.top ) * 0.6 ) + canvasRect.top;
  
    //   if ( y > minY && y < maxY ) {
    //     doNextInteraction();
    //   }
    // }
  
  
  
    // handle pointer down
    function handlePointerDown( event ) {
      doNextInteraction();
    }
    
  
  
    // listen for mouse or touch events
    // window.addEventListener( 'pointermove', handlePointerMove );
    window.addEventListener( 'pointerdown', handlePointerDown );
  
  
  
    // do the next shape interaction
    function doNextInteraction() {
      if (( Date.now() / 1000 ) - minSecondsBetweenShapes > lastShapeTime ) {
  
        lastShapeTime = Date.now() / 1000;
        SceneObjects.doNextInteraction();
  
        clearInterval( intervalTimer );
        intervalTimer = setInterval( doNextInteraction, maxSecondsBetweenShapes * 1000 );
      }
    }
  
  
    // do the first shape interaction after a moment
    intervalTimer = setInterval( doNextInteraction, secondsBeforeFirstShape * 1000 );
    
    
    
    // update
    function update() {
      FrameRate.update();
      SceneObjects.update();
      if ( showStats ) stats.update();
    }
    
    
    
    // animation loop
    function render() {
      // renderer.render( scene, camera.camera );
      composer.render();
    }
    
    function animate() {
      if ( isAnimationRunning ) {
        requestAnimationFrameID = requestAnimationFrame( animate );
        update();
        render();
      }
    }
    
    
    
    
    // public static class:
    
    window.HeroAnimationAPI = {
      
      pause: function() {
        if ( !isAnimationRunning ) return;
        isAnimationRunning = false;
  
        SceneUtils.pausePointerListeners();
      },
      
      unpause: function() {
        if ( isAnimationRunning ) return;
        isAnimationRunning = true;
  
        animate();
        SceneUtils.resumePointerListeners();
      },
    }
    
    
    
    
    // check for slow animation and pause if it's been slow for too long
    var wasAnimationSlowAtLastCheck = false;
  
    function checkForSlowAnimation() {
      
      if ( FrameRate.current <= slowAnimationFPSThreshold ) {
        if ( wasAnimationSlowAtLastCheck ) {
          // console.log( "pausing hero animation because of slow frame rate" );
          window.HeroAnimationAPI.pause();
          return;
        } else {
          wasAnimationSlowAtLastCheck = true;
        }
      } else {
        wasAnimationSlowAtLastCheck = false;
      }
      
      slowAnimationTimerID = setTimeout( checkForSlowAnimation, slowAnimationDurationThreshold * 1000 );
    }
    
    slowAnimationTimerID = setTimeout( checkForSlowAnimation, slowAnimationFirstCheckAt * 1000 );
  
  
  
  
    // allow pausing toggle animation for testing
    function toggleAnimation( event ) {
    
      if ( isAnimationRunning ) {
        window.HeroAnimationAPI.pause();
      } else {
        window.HeroAnimationAPI.unpause();
      }
    }
    
    if ( allowRightClickPause ) window.addEventListener( 'contextmenu', toggleAnimation );
  


    // return a clean up function
    return () => {
      SceneObjects.disposeAll();
      SceneUtils.disposeAll();

      if ( showStats ) container.removeChild( stats.dom );

      clearInterval( intervalTimer );
      cancelAnimationFrame( requestAnimationFrameID );
      clearTimeout( slowAnimationTimerID );

      window.removeEventListener( 'resize', onWindowResize );
      // window.removeEventListener( 'pointermove', handlePointerMove );
      window.removeEventListener( 'pointerdown', handlePointerDown );
      if ( allowRightClickPause ) window.removeEventListener( 'contextmenu', toggleAnimation );
    };
  }, [
    secondsBeforeFirstShape,
    minSecondsBetweenShapes,
    maxSecondsBetweenShapes,
    glowStrength,
    glowRadius,
    glowThreshold,
    slowAnimationDurationThreshold,
    slowAnimationFPSThreshold,
    slowAnimationFirstCheckAt,
    showStats,
  ]);


  // return the jsx
  return (
    <div ref={containerRef} className={classes.canvasContainer}>
      <canvas ref={canvasRef} className={classes.animationCanvas} />
      <div className={classes.overlay} />
    </div>
  );
}