import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'
import * as styles from './Connect.module.scss'

export default function Connect(props) {

    const { data } = props

    const { title, bodyText, link, backgroundImage } = data

    const image = getImage(backgroundImage.asset)

    return (
        <div className={styles.sectionConnect}>
            <GatsbyImage image={image} alt="connect-bg" className={styles.sectionConnectBg}/>
            <div className={styles.sectionConnectBody}>
                <h2 className={styles.sectionConnectTitle}>
                    {title}
                </h2>
                <div className={styles.sectionConnectContent}>
                    {bodyText}
                </div>
                <Link to={link} className={styles.connectButton}>
                    GET IN TOUCH
                </Link>
            </div>
        </div>
    )
}
