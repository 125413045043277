import React from 'react'

const ArrowAngle = (props) => {
  const { color = '#034E6E', width = '8' } = props

  return (
    <svg
      width={width}
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L6 6.625L1 12.25" stroke={color} strokeWidth="2" />
    </svg>
  )
}

export default ArrowAngle
