// extracted by mini-css-extract-plugin
export var sectionHero = "Hero-module--section-hero--4d16e";
export var sectionHeroBg = "Hero-module--section-hero-bg--9467e";
export var sectionHeroContent = "Hero-module--section-hero-content--24b47";
export var sectionHeroContentLeft = "Hero-module--section-hero-content-left--53e64";
export var sectionHeroContentLeftButton = "Hero-module--section-hero-content-left-button--b97dd";
export var sectionHeroContentLeftContent = "Hero-module--section-hero-content-left-content--c095d";
export var sectionHeroContentLeftTitle = "Hero-module--section-hero-content-left-title--72653";
export var sectionHeroContentRight = "Hero-module--section-hero-content-right--9faa0";
export var sectionHeroContentRightBody = "Hero-module--section-hero-content-right-body--6db4b";
export var sectionHeroContentRightButton = "Hero-module--section-hero-content-right-button--6dbce";
export var sectionHeroContentRightContent = "Hero-module--section-hero-content-right-content--fb910";
export var sectionHeroContentRightImg = "Hero-module--section-hero-content-right-img--9da51";
export var sectionHeroContentRightTitle = "Hero-module--section-hero-content-right-title--29b9f";