// private static vars
var filterStrength = 20;
var frameTime = 0;
var _speedFactor = 1;
var lastTime, dt;



// class
export class FrameRate {
  
  // public static methods
  static update() {
    
    // on the first run, initialize and don't do anything else
    if ( !lastTime ) {
      lastTime = performance.now();
      return;
    }
    
    // calculate the smoothed frame time
    var now = performance.now();
    dt = now - lastTime;
    frameTime += ( dt - frameTime ) / filterStrength;
    lastTime = now;

    // calculate speed factor (a multiplier of speed based on a standard of 120 fps)
    _speedFactor = dt / 8.333;
    _speedFactor = Math.min( Math.max( _speedFactor, 0.5 ), 20 );
  }
  
  
  // public static variables
  static get current() {
    return Math.round( 1000 / frameTime );
  }

  static get speedFactor() {
    return _speedFactor;
  }
  
  static set filterStrength( _filterStrength ) {
    filterStrength = _filterStrength;
  }
}