import React from 'react'

const EbookArrow = (props) => {
  const { width = '15' } = props

  return (
    <svg width={width} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7181 1.29289C12.1059 0.902369 12.7347 0.902369 13.1225 1.29289L21.7692 10L13.1225 18.7071C12.7347 19.0976 12.1059 19.0976 11.7181 18.7071C11.3303 18.3166 11.3303 17.6834 11.7181 17.2929L17.9673 11H1.99307C1.44461 11 1 10.5523 1 10C1 9.44772 1.44461 9 1.99307 9H17.9673L11.7181 2.70711C11.3303 2.31658 11.3303 1.68342 11.7181 1.29289Z"
        fill="#DA437E"
        stroke="#DA437E"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default EbookArrow
