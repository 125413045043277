import React from 'react';


const ArrowIcon = (props) => {

  const {width = '7'} = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 7 10">
      <path stroke="#DA437E" strokeLinecap="round" d="M1 .5L5.5 5 1 9.5"></path>
    </svg>
  )
}

export default ArrowIcon
